<template>
  <div style="padding:20px 0px; background-color: white; border-radius: 10px;">
    <div v-if="isAdmin || isTerminalLogs || isTerminalOps || isTerminalTL">
      <div class="box-card">
        <el-row>
          <el-col :span="24" class="text-right p-1 laptop:pb-2 desktop:pb-4">
            <el-input :placeholder="$t('btn.SearchBargeOp')" class="searchShip" size="small" v-model="searchShip"
              @keyup.enter.native="getBargeOpList()">
              <span>
                <svg-icon iconClass="search" />
              </span>
            </el-input>
          </el-col>
        </el-row>
        <div class="box-card p-1 isMobileScreen " v-if="isMobileScreen && !isBargeOp">
          <el-collapse accordion>
            <el-collapse-item v-for="(item, index) in bargeOpList" :key="index" :class="{ active: activeBargeOpListItem == index }" @click="handleBargeOpListItemClick(item, index)">
              <template slot="title">
                <div class="px-2">
                  <el-avatar  :size="29" :src="circleUrl"></el-avatar>
                </div>
                <div class="flex flex-row justify-around items-center w-full pr-1.5" >
                  <div class="w-64 xxs:w-56">
                    <p >{{ item.nickName }}</p>
                    <p>{{ item.email }}</p>
                  </div>
                  <div >
                  <el-tag class="Statustag " :type="item.status == '0'
                      ? 'success'
                      : item.status == '1'
                        ? 'danger'
                        : 'pink'
                    " disable-transitions :color="item.status == '0'
                      ? '#CEF9CD'
                      : item.status == '1'
                        ? '#FFE6E1'
                        : '#FFEFD9'
                    ">
                    {{ item.status == "0" ? $t("state.Active") : item.status == "1" ? $t("state.Deactivated") :
                      $t("state.PendingVerification") }}
                  </el-tag> </div>
                </div>
              </template>
              <div class="p-1.5">
                <el-table v-loading="bargeListLoading" max-height="400px"
                  :data="selectedBargeOpBarges" :cell-style="getCellStyle" :header-cell-style="{
                    height: '25px',
                    background: '#fff',
                    'border-top': '1px solid #D6D9E1 ',
                    'border-bottom': '1px solid #D6D9E1',
                  }">
                  <el-table-column min-width="20"  align="left" type="index" :label="$t('table.no')" />
                  <el-table-column min-width="160" align="left" prop="userName" :label="$t('table.username')" />
                  <el-table-column min-width="100" align="left" prop="mvpShipImo" :label="$t('table.iMONumber')" />
                  <el-table-column min-width="160" align="left" prop="email" :label="$t('table.EmailAddress')" />
                  <el-table-column min-width="60" align="left" prop="role" :label="$t('table.Role')">
                    <template>
                      Barge
                    </template>
                  </el-table-column>
                  <el-table-column min-width="80" align="left" prop="address" :label="$t('table.Status')">
                    <template slot-scope="scope">
                      <el-tag class="Statustag" :type="scope.row.status == '0'
                          ? 'success'
                          : scope.row.status == '1'
                            ? 'danger'
                            : 'pink'
                        " disable-transitions :color="scope.row.status == '0'
                        ? '#CEF9CD'
                        : scope.row.status == '1'
                          ? '#FFE6E1'
                          : '#FFEFD9'
                      ">
                        {{ scope.row.status == "0" ? $t("state.Active") : scope.row.status == "1" ?
                          $t("state.Deactivated") : $t("state.PendingVerification") }}
                      </el-tag>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div class="box-card" v-else>
          <el-row v-loading="bargeOpListloading">
            <el-col :span="9" style="padding:10px;">
              <div class="content" style="border-radius: 10px;border: 1px solid rgb(214, 217, 225);">
                <div class="bargeOpList" v-for="(item, index) in bargeOpList" :key="index"
                  :class="{ active: activeBargeOpListItem == index }" @click="handleBargeOpListItemClick(item, index)">
                  <el-avatar class="mr-5" :size="29" :src="circleUrl"></el-avatar>
                  <div class="flex flex-row justify-between items-center w-full">
                    <div>
                      <p>{{ item.nickName }}</p>
                      <P>{{ item.email }}</P>
                    </div>
                    <el-tag class="Statustag" :type="item.status == '0'
                        ? 'success'
                        : item.status == '1'
                          ? 'danger'
                          : 'pink'
                      " disable-transitions :color="item.status == '0'
                        ? '#CEF9CD'
                        : item.status == '1'
                          ? '#FFE6E1'
                          : '#FFEFD9'
                      ">
                      {{ item.status == "0" ? $t("state.Active") : item.status == "1" ? $t("state.Deactivated") :
                        $t("state.PendingVerification") }}
                    </el-tag>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="15" style="padding: 10px;">
              <div class="bargeTable" style="border-radius: 10px; border: 1px solid rgb(214, 217, 225);">
                <el-table v-loading="bargeListLoading" height="calc(88vh - 80px - 64px - 56px)"
                  :data="selectedBargeOpBarges" :cell-style="getCellStyle" :header-cell-style="{
                    height: '55px',
                    background: '#fff',
                    'border-top': '1px solid #D6D9E1 ',
                    'border-bottom': '1px solid #D6D9E1',
                  }">
                  <el-table-column align="left" type="index" :label="$t('table.no')" />
                  <el-table-column align="left" prop="userName" :label="$t('table.username')" />
                  <el-table-column align="left" prop="mvpShipImo" :label="$t('table.iMONumber')" />
                  <el-table-column align="left" prop="email" :label="$t('table.EmailAddress')" />
                  <el-table-column align="left" prop="role" :label="$t('table.Role')">
                    <template>
                      Barge
                    </template>
                  </el-table-column>
                  <el-table-column align="left" prop="address" :label="$t('table.Status')">
                    <template slot-scope="scope">
                      <el-tag class="Statustag" :type="scope.row.status == '0'
                          ? 'success'
                          : scope.row.status == '1'
                            ? 'danger'
                            : 'pink'
                        " disable-transitions :color="scope.row.status == '0'
                        ? '#CEF9CD'
                        : scope.row.status == '1'
                          ? '#FFE6E1'
                          : '#FFEFD9'
                      ">
                        {{ scope.row.status == "0" ? $t("state.Active") : scope.row.status == "1" ?
                          $t("state.Deactivated") : $t("state.PendingVerification") }}
                      </el-tag>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div v-else-if="isBargeOp" class="box-card">
      <el-table :cell-style="getCellStyle" max-height="700" :data="bargeList" :header-cell-style="{
        height: '55px',
        background: '#fff',
        'border-top': '1px solid #D6D9E1 ',
        'border-bottom': '1px solid #D6D9E1',
      }">
        <el-table-column align="left" type="index" :label="$t('table.no')" />
        <el-table-column align="left" prop="nickName" :label="$t('table.Name')" />
        <el-table-column align="left" prop="email" :label="$t('table.EmailAddress')" />
        <el-table-column align="left" prop="mvpShipImo" :label="$t('table.iMONumber')" />
        <el-table-column align="left" prop="role" :label="$t('table.Role')">
          <template>
            Barge
          </template>
        </el-table-column>
        <el-table-column align="left" prop="address" :label="$t('table.Status')">
          <template slot-scope="scope">
            <el-tag class="Statustag" :type="scope.row.status == '0'
                ? 'success'
                : scope.row.status == '1'
                  ? 'danger'
                  : 'pink'
              " disable-transitions :color="scope.row.status == '0'
                ? '#CEF9CD'
                : scope.row.status == '1'
                  ? '#FFE6E1'
                  : '#FFEFD9'
              ">
              {{
                scope.row.status == "0"
                ? $t("state.Active")
                : scope.row.status == "1"
                  ? $t("state.Deactivated")
                  : $t("state.PendingVerification")
              }}
            </el-tag>
          </template>
        </el-table-column>
        <!-- <el-table-column fixed="right" width="120">
          <template slot-scope="scope">
            <el-button size="small" type="primary" plain @click="handleRemoveBarge(scope.row.userId)">{{ $t("btn.remove") }}</el-button>
          </template>
        </el-table-column> -->
      </el-table>
    </div>
    <el-dialog width="360px" :title="isAddBargeOp ? $t('sm.AddBargeOp') : $t('sm.ModifyBargeOp')"
      :visible.sync="isDisplayBargeOpDialog" @close="handleCloseBargeOpDialog">
      <el-form ref="bargeOpForm" size="small" label-position="top" :model="bargeOpForm" :rules="rules">
        <el-form-item :label="$t('sm.BargeOpName')" prop="nickName">
          <el-input size="small" v-model="bargeOpForm.nickName" clearable autocomplete="off" />
        </el-form-item>
        <el-form-item prop="email" :label="$t('sm.EmailAddress')">
          <el-input size="small" v-model="bargeOpForm.email" clearable autocomplete="off" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleSaveBargeOp()">
          {{ $t("btn.save") }}
        </el-button>
      </div>
    </el-dialog>
    <el-dialog width="360px" :title="$t('sm.AddShip')" :visible.sync="isDisplayBargeDialog">
      <el-form>
        <el-form-item :label="$t('sm.SelectBarge')">
          <el-select style="width: 100%" v-model="bargeForm.imo" clearable placeholder="" filterable bargeNameloading
            remote :remote-method="handleSearchBarge" class="bargeNameinput" :loading="isBargeOptionsLoading">
            <el-option v-for="item in bargeoptions" :key="item.value" :disabled="item.unFlag == 1"
              :label="item.imo + `(${item.bargeName})`" :value="item.imo">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleAddShip()">
          {{ $t("btn.save") }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getCellStyle } from "@/utils/getCellStyle";
import {
  getCSSList,
  addUser,
  editUser,
  deleteUser,
  searchBarge
} from "@/services/user";
import {
  attachBarge,
  detachBarge,
  getAttachedBargeList
} from "@/services";
export default {
  data() {
    return {
      isBargeOptionsLoading: false,
      bargeoptions: [],
      circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      isDisplayBargeOpDialog: false,
      isDisplayBargeDialog: false,
      bargeOpForm: {
        nickName: "",
        email: ""
      },
      bargeForm: {
        imo: ""
      },
      rules: {
        nickName: [
          { required: true, message: "Please Input", trigger: "blur" },
        ],
        email: [{ required: true, message: "Please Input", trigger: "blur" }],
      },
      isAddBargeOp: true,
      bargeOpList: [],
      bargeOpListloading: false,
      bargeOpBargeList: [],
      bargeListLoading: false,
      activeBargeOpListItem: 0,
      prevActiveBargeOpListItem: 0,
      searchShip: "",
      selectedBargeOpBarges: [],
      bargeList: [],
      selectedBargeOp: null
    }
  },
  computed: {
    ...mapState(["userInfo","screenType"]),
    
    isAdmin() {
      return this.userInfo.userType == 0;
    },
    isTerminalLogs() {
      return this.userInfo.userType == 1 && this.userInfo.role == 1;
    },
    isTerminalOps() {
      return this.userInfo.userType == 1 && this.userInfo.role == 2;
    },
    isTerminalTL() {
      return this.userInfo.userType == 1 && this.userInfo.role == 3;
    },
    isBargeOp() {
      return this.userInfo.userType == 4 && this.userInfo.role == 9;
    },
    isMobileScreen(){
      return this.screenType?.isMobileScreen??false;
    },
  },
  created() {
    this.getList()
  },
  methods: {
    getCellStyle,
    getList() {
      if (!this.isBargeOp) {
        this.getBargeOpList();
      } else {
        this.getBargeList()
      }
    },
    async handleSearchBarge(query) {
      if (query !== '') {
        this.isBargeOptionsLoading = true;
        try {
          const res = await searchBarge(query);
          this.bargeoptions = res.data;
        } catch (error) {
          this.bargeoptions = [];
        } finally {
          this.isBargeOptionsLoading = false;
        }
      } else {
        this.bargeoptions = [];
      }
    },
    handleBargeOpListItemClick(item, index) {
      this.selectedBargeOp = item;
      this.selectedBargeOpBarges = this.bargeOpBargeList[index]
      this.activeBargeOpListItem = index
    },
    getBargeOpList() {
      this.bargeOpListloading = true
      getCSSList(4, this.searchShip)
        .then((res) => {
          if (res.code == 200) {
            this.bargeOpList = res.data
            this.bargeOpBargeList = res.data.map(bargeOp => bargeOp.sysUsers)
            if (this.bargeOpBargeList.length > 0) {
              this.selectedBargeOp = this.bargeOpList[this.prevActiveBargeOpListItem]
              this.selectedBargeOpBarges = this.bargeOpBargeList[this.prevActiveBargeOpListItem]
              this.activeBargeOpListItem = this.prevActiveBargeOpListItem
            }
          }
        })
        .finally(() => {
          this.bargeOpListloading = false
        });
    },
    getBargeList() {
      getAttachedBargeList().then((res) => {
        if (res.code == 200) {
          this.bargeList = res.data
        }
      })
    },
    handleAddShip() {
      let name = this.bargeoptions.find(b => b.imo === this.bargeForm.imo).bargeName
      attachBarge({ imo: this.bargeForm.imo, name: name, bargeOperatorId: this.selectedBargeOp.userId })
        .then((res) => {
          if (res.code == 200) {
            this.isDisplayBargeDialog = false
            this.handleAddDeleteShipSuccess()
            this.bargeForm.imo = ""
          }
        })
    },
    handleRemoveBarge(userId) {
      this.$confirm(this.$t("sm.RemoveBargeWarningMsg"), this.$t("sm.RemoveBargeWarningTitle"), {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        detachBarge({ bargeId: userId, bargeOperatorId: this.userInfo.userId }).then((res) => {
          if (res.code == 200) {
            this.handleAddDeleteShipSuccess()
          }
        })
      }).catch(() => {
      });
    },
    handleAddDeleteShipSuccess() {
      this.$message.success(this.$t("status.success"));
      this.prevActiveBargeOpListItem = this.activeBargeOpListItem;
      this.getList()
    },
    handleSaveBargeOp() {
      if (this.isAddBargeOp) {
        let user = {
          email: this.bargeOpForm.email,
          nickName: this.bargeOpForm.nickName,
          role: 9,
          userType: 4
        }
        addUser(user).then((res) => {
          if (res.code == 200) {
            this.handleSaveBargeOpSuccess()
          }
        })
      } else {
        let user = {
          userId: this.bargeOpForm.userId,
          nickName: this.bargeOpForm.nickName,
        }
        editUser(user).then((res) => {
          if (res.code == 200) {
            this.handleSaveBargeOpSuccess()
          }
        })
      }
    },
    handleSaveBargeOpSuccess() {
      this.isDisplayBargeOpDialog = false
      this.$message.success(this.$t("status.success"))
      this.getBargeOpList()

    },
    handleCloseBargeOpDialog() {
      this.bargeOpForm = {
        nickName: "",
        email: ""
      }
    }
  }
}
</script>
<style scoped lang="scss">
.searchShip {
  width: 275px;
  height: 32px;
}

.el-button--warning.is-plain:hover {
  background: #fff;
  color: #008CDB;
  border-color: #008CDB;
}

.el-button--warning {
  border-color: #008CDB;
}

.el-button--warning:focus {
  background: #fff !important;
  color: #008CDB !important;
  border-color: #008CDB;
}

.el-button--default {
  color: #fff;
  background-color: #008CDB;
}

.el-button--default.is-plain:hover {
  color: #fff;
  background: #008CDB;
  border-color: #008CDB;
}

.el-button--default:focus {
  color: #fff !important;
  background: #008CDB !important;
  border-color: #008CDB;
}

.placeholder {
  height: 64px;
  background-color: #fff;
}

.content {
  height: calc(88vh - 80px - 32 * 2px - 56px);

  background-color: #e4e4e4;
  overflow-y: auto;

  .bargeOpList {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 14px 36px 15px 30px;
    box-sizing: border-box;
    height: 78px;
    font-size: 11px;
    border-top: 1px solid #d6d9e1;
    border-bottom: 1px solid #d6d9e1;

    .bargeOpBtn {
      display: flex;
      margin-left: 86px;

      .svgs {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 50%;
        border: 1.5px solid #82889c;
        width: 35px;
        height: 35px;
        margin-right: 12px;
      }
    }
  }

  .active {
    background: #ffffff;
  }
}

.bargeTable {
  overflow-y: scroll;
}

.boxtop {
  padding-right: 6px;

  .Topbtn {
    text-align: right;
  }
}

.el-table {
  font-size: 12px;
}

.isMobileScreen {
  ::v-deep .el-collapse-item__header{
    line-height:20px;
    height: 60px;
    padding:10px 0px;
    p {
        font-size: 12px;
        word-wrap:break-word;
    }
  }
  .el-tag{
    font-size: 10px;
    padding: 0 4px;
  }
}

</style>